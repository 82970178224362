<template>
  <div class="mt-4 w-100 flex flex-col gap-3 p-1">
    <span class="w-100 h-px bg-resistantScrollbarTrackColor relative z-10">
      <span
        v-for="(date, i) in props.datesStringArray"
        v-bind:key="date"
        class="timeline-dot"
        :class="activeIndex === i ? 'active' : ''"
        :style="getTimelineDotStyles(date, activeIndex === i)"
      >
      </span>
    </span>
    <div class="w-100 flex justify-space-between text-resistantSecondaryTextColor text-xs">
      <span>{{ formatDateToLocal(oldestDate) }}</span>
      <span v-for="date in getDatesBetween(oldestDate, newestDate, 3)" v-bind:key="date">
        {{ formatDateToMonthAndYear(date) }}
      </span>
      <span>{{ formatDateToLocal(newestDate) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { getNewestDate,getOldestDate } from "@/utils/date";
import { formatDateToLocal, formatDateToMonthAndYear } from "@/utils/formatDate";

const props = defineProps<{
  datesStringArray: string[];
  activeIndex: number;
}>();

const newestDate = computed<string>(() => {
  return getNewestDate(props.datesStringArray);
});

const oldestDate = computed<string>(() => {
  return getOldestDate(props.datesStringArray);
});

const getTimelineDotStyles = (date: string, active: boolean) => {
  return {
    left: `calc(${getDatePositionOnTimeline(date, oldestDate.value, newestDate.value)}% - ${
      active ? "4" : "3"
    }px)`,
  };
};

const getDatePositionOnTimeline = (date: string, oldestDate: string, newestDate: string) => {
  const oldest = new Date(oldestDate).getTime();
  const newest = new Date(newestDate).getTime();
  const dateToCheck = new Date(date).getTime();
  const range = newest - oldest;
  const position = dateToCheck - oldest;
  return (position / range) * 100;
};

const getDatesBetween = (startDate: string, endDate: string, numberOfDates: number): string[] => {
  const dates = [];
  const start = new Date(startDate);
  const end = new Date(endDate);
  const range = end.getTime() - start.getTime();
  const step = range / numberOfDates;

  for (let i = 0; i < numberOfDates; i++) {
    dates.push(new Date(start.getTime() + step * i).toISOString());
  }

  return dates;
};
</script>

<style lang="scss" scoped>
.timeline-dot {
  border-radius: 50%;
  position: absolute;
  top: -2.5px;
  width: 6px;
  height: 6px;
  background-color: var(--resistantSecondaryTextColor);

  &.active {
    top: -3.5px;
    width: 8px;
    height: 8px;
    background-color: var(--resistantPrimaryColor);
    z-index: 50;
  }

  &:not(.active)::after {
    content: "";
    position: absolute;
    width: 12px;
    height: 12px;
    top: -3px;
    left: -3px;
    border-radius: 50%;
    background-color: var(--resistantMetadataPrimaryBackgroundColor);
    z-index: -1;
  }
}
</style>
