import { ScannerErrorData, ScannerErrorTypes } from "./types";

export const SCANNER_ERRORS_DATA: { [key: string]: ScannerErrorData } = {
  [ScannerErrorTypes.UNKNOWN]: {
    title: "Something went wrong",
    message:
      "There was an unknown error when analyzing this document.\nIf the error persists, please check the document.",
    getMessageWithDocName: (docName) => `Something went wrong with "${docName}"`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: true,
  },
  [ScannerErrorTypes.UNAUTHORIZED]: {
    title: "This document is password protected",
    message: "Please unlock the document and resubmit it.",
    getMessageWithDocName: (docName) => `"${docName}" is password protected`,
    defaultStatusCode: 401,
    isFatal: false,
    canShowTryAgain: false,
  },
  [ScannerErrorTypes.FORBIDDEN]: {
    title: "You don’t have the rights to run an analysis",
    message:
      "You can only read analysis results. If you think you should be able to run an analysis, please contact your administrator.",
    getMessageWithDocName: () => `You don’t have the rights to run an analysis`,
    defaultStatusCode: 403,
    isFatal: true,
    canShowTryAgain: false,
  },
  [ScannerErrorTypes.ANALYSIS_GET_RESULT]: {
    title: "Something went wrong with our results",
    message: "Please try again",
    getMessageWithDocName: (docName) => `Something went wrong with the results of "${docName}"`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: true,
  },
  [ScannerErrorTypes.ANALYSIS_SUBMIT_FILE]: {
    title: "Something went wrong with the submission",
    message: "Please resubmit the document",
    getMessageWithDocName: (docName) => `Something went wrong with the submission of "${docName}"`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: false,
  },
  [ScannerErrorTypes.ANALYSIS_FAILED]: {
    title: "Something went wrong with our analysis",
    message: "Please try again. If it happens again. Please resubmit the document.",
    getMessageWithDocName: (docName) => `Analysis failed for "${docName}"`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: true,
  },
  [ScannerErrorTypes.ANALYSIS_TIMEOUT]: {
    title: "The analysis took too long",
    message: "Please try it again",
    getMessageWithDocName: (docName) => `Analysis for "${docName}” took too long`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: true,
  },
  [ScannerErrorTypes.ANALYSIS_ID_NOT_EXISTS]: {
    title: "This analysis doesn’t exist",
    message:
      "Please check the ID and try again. If this happens again, its retention period may have expired.",
    getMessageWithDocName: () => `Analysis doesn’t exist. Please check the ID and try again."`,
    defaultStatusCode: 404,
    isFatal: false,
    canShowTryAgain: true,
  },
  [ScannerErrorTypes.LOAD_DOC]: {
    title: "We couldn’t load the document",
    message: "Please try loading it again.",
    getMessageWithDocName: (docName) => `"${docName}" failed to load`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: true,
  },
  [ScannerErrorTypes.UNSUPPORTED_FILE_TYPE]: {
    title: "We can’t read this document format",
    message: "Please submit files in PDF, JPEG, PNGs, TIFF, or GIF",
    getMessageWithDocName: (docName) => `Unsupported file type for document "${docName}"`,
    defaultStatusCode: 400,
    isFatal: false,
    canShowTryAgain: false,
  },
  [ScannerErrorTypes.TOKEN_EXPIRED]: {
    title: "Your session has expired",
    message: "Please log in again",
    getMessageWithDocName: () => `Your session has expired`,
    defaultStatusCode: 401,
    isFatal: false,
    canShowTryAgain: false,
  },
};
