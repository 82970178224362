const formatDate = (date?: string, showExactTime: boolean = true) => {
  if (!date) return "";
  if (!showExactTime) return new Date(date).toLocaleDateString();
  return new Date(date).toLocaleDateString() + " " + new Date(date).toLocaleTimeString();
};

export const formatDateToLocal = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString();
};

export const formatDateToMonthAndYear = (dateString: string): string => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString().slice(-2);
  return `${month}/${year}`;
};

export default formatDate;
