import { defineStore } from "pinia";

interface IUserState {
  email?: string;
  permissions: {
    canRead: boolean;
    canWrite: boolean;
  };
}

export const defaultState: IUserState = {
  permissions: {
    canRead: false,
    canWrite: false,
  },
};

export const useUserStore = defineStore({
  id: "user",
  state: () => defaultState as IUserState,
  getters: {},
  actions: {
    setPermissions(permissions: IUserState["permissions"]) {
      this.permissions = permissions;
    },
    setUserEmail(email: string) {
      this.email = email;
    },
  },
});
