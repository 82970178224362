import { STATIC_FILES_PATH } from "@/configs/global";

const getFontUrl = (fontName: string) => {
  return `url("${STATIC_FILES_PATH}fonts/${fontName}")`;
};

export const fontFaces = `
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    src: ${getFontUrl("Inter-Light.ttf")} format("truetype");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: ${getFontUrl("Inter-Regular.ttf")} format("truetype");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    src: ${getFontUrl("Inter-Medium.ttf")} format("truetype");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: ${getFontUrl("Inter-SemiBold.ttf")} format("truetype");
  }
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: ${getFontUrl("Inter-Bold.ttf")} format("truetype");
  }
`;

export const addFontFaces = () => {
  const styleElement = document.createElement("style");
  styleElement.textContent = fontFaces;
  document.head.appendChild(styleElement);
};
