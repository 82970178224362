export const getOldestDate = (dates: string[]) => {
  return dates.reduce((oldest, date) => {
    if (new Date(date) < new Date(oldest)) return date;
    return oldest;
  }, dates[0]);
};

export const getNewestDate = (dates: string[]) => {
  return dates.reduce((newest, date) => {
    if (new Date(date) > new Date(newest)) return date;
    return newest;
  }, dates[0]);
};
